

.panelTimeOptionDropdown {
    display: flex;
    margin: 0px 10px 0px 5px;
    cursor: pointer;
}
.panelTimeOptionText {
    cursor: pointer;
}
.wellDetailPanelHeader {
    cursor: pointer;
}

.wellDetailWrapper {
    min-height: $well-detail-panel-height;
    // max-height: $well-detail-panel-height;
    margin: 5px 0px;
}

.detailSpinner {
    height: 70vh !important; 
}

.chartTabs {
    margin-bottom: .75rem !important;
    ul {
        cursor: pointer;
    }
}

.chartControlsWrapper {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: center;
    line-height: 1.5rem;
}

.detailContentWrapper {
    margin: 0px 10px !important;
}

.detailPanelWrapper {
    position: relative;
    z-index: 20;
    height: 0px;
    padding: 0px 1.5rem 0px 0px;
    left: 40%;
    top: 15px;
}

.detailTableWrapper {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 15px;
}

.detailsData {
    flex-wrap: wrap;
    .field {
        flex-wrap: wrap;
        margin-bottom: 4px;
        .label {
            margin-bottom: 0px;
        }
    }
}

.detailsDataWrapper {
    padding: 0px 5px;
    margin-bottom: 15px;
}

.detailsDataHeader {
    font-size: 1rem;
    margin-bottom: 3px;
    font-weight: bold;
}

.nitrateDetailsData {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.nitrateDetailsField {
    width: 50%;
}

// time window dropdown
.timeWindowDropdownWrapper, .analyteDropdownWrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    border-bottom: solid 1px lightgray;
    box-shadow: inset 0px -10px 4px -11px rgba(0,0,0,0.86);
}

.timeWindowOptionWrapper, .analyteOptionWrapper {
    margin-bottom: 5px;
    .activeOption {
        font-weight: bold;
        color: $primary;
    }
    .timeWindowOptionText {
        cursor: pointer;
        font-size: 1rem;
    }
}

.analyteOptionWrapper {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 0.75rem;
}

.analyteDropdownWrapper {
    overflow-y: auto;
    max-height: 150px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track
    {
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.116);
        border-radius: 20px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar {
        display: contents;
        width: 4px;
        background-color: #F5F5F5;
        }
    &::-webkit-scrollbar-thumb
    {
        border-radius: 20px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(99, 99, 99, 0.911);
    }
}