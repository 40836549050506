$form-vertical-spacing: 20px 0px;
$table-vertical-spacing: 0px 0px 20px 0px;

.buttonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.disabled-link {
    color: #DEDEDE !important;
}

.formWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: $form-vertical-spacing;
}

.saveButtonWrapper {

}

.newUploadButton {
    margin-bottom: -12px !important;
}

.bulkSubmitWrapper {
    padding: 0rem 1.5rem 0rem 1.5rem;
    .bulkSubmitButton {

    }
}

.inProgressSection {
    padding-top: 0.5rem;
}

.buttonWrapper {
    display: flex;
    justify-content: space-around;
    align-self: center;
    width: 100%;
    margin: $form-vertical-spacing;
}

.editableTableWrapper {
    margin: $table-vertical-spacing;
    padding: 0px 10px;
    overflow-x: auto;
    tbody>tr>td>div {
        margin: unset !important;
        padding: unset !important;
        .formInputLayout {
            padding: unset !important;
            .field-body {
                margin: unset !important;
                padding: unset !important;
            }
        }
    }
}

.newTableRowWrapper {
    margin: $form-vertical-spacing;
    display: flex;
    width: 100%;
    justify-content: center;
}

.formTableWrapper {
    width: 100%;
    // overflow-x: auto;
    border: solid 2px #efefef;
    border-radius: 5px;
    margin: $form-vertical-spacing;
}

.vertical-align {
    vertical-align: middle !important;
}

.clearinghouseNumber {    
    flex: 1;
    display: flex;
    justify-content: center;
    margin-right: 20%;   
}

.formHeader {
    display: flex;
    justify-content: center;
    font-size: large;
    font-weight: 500;
}

.breadcrumbAlignment {
    margin: 10px 0px 0px 10px;
}

.matchingFacilityText {
    margin: 10px 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    // span {
    //     max-width: 700px;
    // }
}

.formSectionWrapper { 
    width: 100%;
}

.formSectionSubtitle {
    display: flex;
    justify-content: space-between;
}

.sectionDisclaimer {
    display: flex;
    align-items: center;   
}
.requiredStar > svg {
    height: 0.75em;
    width: 0.75em;
    margin-bottom: 2px;
    margin-left: 2px;
}

.simpleTableArrow {
    display: flex;
    align-items: center;
}
.dnrHelperImageWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.submitButtonWrapper {
    padding: 0.5em 0.75em 1em;
    .button {
        width: 100%;
    }
}

.eddForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.eddForm, .eddButtonWrapper, .eddErrorWrapper {
    padding: 20px 0px;
}
.eddButtonWrapper, .eddErrorWrapper {
    display: flex;
    justify-content: center;
}

.eddButtonWrapper {
    .button {
        margin: 0px 10px;
    }
}

.validationIndicatorWrapper {
    display: flex;
    justify-content: center;
    span {
        margin-left: 10px;
    }
}

.eddResultsWrapper {
    padding: 20px 0px;
}

.sideNavLink {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .icons {
        display: flex;
        svg {
            margin: 2px;
        }
    }
}

.padding-top-sm {
    padding-top: 6px;
}

.assignButton {
    color: $primary;
}

.unassignButton {
    color: $danger;
}

.assignmentWrapper {
    width: 100%;
    display: flex;
}

.assignmentActionsPositioning {
    display: inline-flex;
    flex-basis: 10%;
    width: 10%;
    flex-shrink: 0;
}

.assignmentActionsWrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.assignmentActionButton {
    display: flex;
    justify-content: center;
    padding: .5rem 0px;
    cursor: pointer;
    font-size: 1.25rem;
    width: 100%;
    .saveButtonWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .button {
        width: 50%;
    }
}

.assignmentSaveAndContinueWrapper {
    padding-top: 1.5rem;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.facilityAssignmentTablePositioning {
    width: 45%;
    display: inline-flex;
    flex-basis: 45%;
}

.fullWidthPositioning {
    width: 100%;
    flex-basis: 100%;
}
.facilityAssignmentTableWrapper {
    max-width: 100%;
    width: 100%;
}

.assignedFacilitiesPositioning {
    @extend .facilityAssignmentTablePositioning;
}

.possibleFacilitiesPositioning {
    @extend .facilityAssignmentTablePositioning;
}

.assignedFacilitiesWrapper {
    @extend .facilityAssignmentTableWrapper;
    .form {
        margin-top: unset;
        margin-bottom: unset;
    }

}

.possibleFacilitiesWrapper {
    @extend .facilityAssignmentTableWrapper;
}

.facilityAssignmentTable {
    min-height: $explorer-table-height;
    .table {
        font-size: 0.75rem;
    }
}
// .link.is-warning {
//     color: yellow;
//     &:before {
//         content: " * ";
//         height: 2px;
//         width: 2px;
//         background-color: black;
//     }
// }

// .reactSelect__single-value,
// .reactSelect__placeholder {
//     position: static !important;
//     top: auto !important;
//     left: auto !important;
//     transform: none !important;
//     max-width: none !important;
//   }
// .reactSelect__menu { min-width: 100% !important; left: 0 !important; right: unset !important; }