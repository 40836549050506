@import '~bulma/bulma.sass';
@import 'styles/variables.scss';
@import "~react-datepicker/dist/react-datepicker.css";
@import '~ol/ol.css';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~react-toastify/dist/ReactToastify.css';
@import 'styles/theme.scss';
@import 'styles/utilities.scss';
@import 'styles/elem.scss';
@import 'styles/form.scss';
@import 'styles/Explorer.scss';
@import 'styles/map.scss';
@import 'styles/chart.scss';
@import 'styles/wellDetail.scss';
@import 'styles/navbar.scss';
@import 'styles/submission.scss';

html {
    overflow: hidden;
    &.has-navbar-fixed-top {
        padding-top: $navbar-height;
    }
}

.pageContent {
    max-height: calc(100vh - #{$navbar-height});
    overflow-y: auto;
}

// navbar
.globalResetButton {
    a {
        color: white;
        display: flex;
        align-items: center;
        svg {
            margin-right: 5px;
        }
    }
}