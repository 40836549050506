// spinner
.spinnerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - #{$navbar-height} - #{$explorer-filterbar-height} - #{$explorer-tablefooter-height});

}

.spinner {
    display: flex;
    width: 100%;
}

.spinnerIcon {
    display: flex;
    height: 16px;
    width: 16px;
}

.inlineSpinner {
    height: 100%;
}

// panel
.dashboardPanel {
    margin: 0rem;
    padding-top: $explorer-panel-padding-top;
}

.dashboardPanelShadow {
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);
}

.dashboardPanelBody {
    background-color: $white;
}

.dashboardPanelHeader {
    padding: .25rem .5rem;
    background-color: $primary;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: medium;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.24);
}

.panelHeaderText {
    display: flex;
}

.panelButtons {
    display: flex;
    align-items: center;
}

.panelButton {
    display: flex;
    align-items: center;
    user-select: none;
}

.resetButtons {
    display: flex;
    flex-direction:row;
    padding-right: 1em;
    .zoomButton {
        padding-right: 0.5em;
    }
}


.panelHeaderControls {
    display: flex;
    justify-content: flex-start;
}

.panelHeaderIconWrapper {
    display: flex;
    align-items: center;
}

.panelHeaderIconDiv {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    height: 100%;
    cursor: pointer;
            

    &.active {
        opacity: 1;
        background-color: white;
        border-radius: 4px;
        color: $color-6;
    }
    .panelHeaderIcon {
        &.inactive {
            opacity: 0.75;
        }
        &:hover {
            opacity: 1;
        }
        display: flex;
        height: 16px;
        width: 16px;
        &.large {
            height: 20px;
            width: 20px;
            margin-top: 3px;
        }
    }
}


button.panelHeaderIconDiv {
    color: white !important;
    border-color: white !important;
    margin-left: 10px;
    &:hover {
        color: inherit !important;
        border-color: inherit !important;
    }
    span {
        &.icon {
            color: white;
        }
    }
}

.controlsGroupedWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
    padding-right: 20px;
}

// no data component
.noDataComponentWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    
    .noDataComponent {
        display: flex;
        justify-content: center;

        .noDataText {
            
        }

        .noDataResetFilterText {
            text-decoration: underline;
            cursor: pointer;
            margin-left: 3px;
        }
    }
}

// tooltip triggers
.info-icon {
    color: $primary;
}

.__react_component_tooltip {
    background-color: $primary;
    &::after {
        border-top-color: $primary;
    }
}

.iconTooltip {
    background-color: $dark !important;
    font-weight: lighter;
    font-size: 11px;
    padding: 5px 10px;
    &::after {
        border-top-color: $dark !important;
    }
}
// bulma overrides 

// columns
.columns.is-variable {
    margin-left: unset;
    margin-right: unset;
}
// tabs 
.tabs div {
    align-items: center;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #4a4a4a;
    display: flex;
    justify-content: center;
    margin-bottom: -2px;
    padding: 0.5em 1em;
    vertical-align: top;
}

.tabs.is-boxed div {
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.tabs li.is-active div {
    border-bottom-color: #3273dc;
}

.tabs.is-boxed li.is-active div {
    background-color: white;
    border-color: #dbdbdb;
    border-bottom-color: transparent !important;
}

// forms
.label {
    white-space: nowrap;
}
.control {
    .input:focus ~ .icon {
        color: $input-icon-color !important;
    }
    .input:focus ~ .icon:hover {
        color: $primary !important;
        cursor: pointer;
    }
    .icon {
        pointer-events: initial !important;
        &:hover {
            cursor: pointer;
        }
        &:focus {
            color: $primary !important;
        }
    }
}

// tooltip
.tooltipHover {
    opacity: 1 !important;
    pointer-events: auto !important;
    cursor: default;
    max-width: 400px;
    white-space: normal;
    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
    }
   }

// navbar

.navbar, .navbar-brand {
    min-height: .5rem;
}

.navbar-burger {
    height: $navbar-height;
    width: $navbar-height;
}
.navbar-link, .navbar-item {
    padding: 0.2rem 2rem 0.2rem 0.5rem !important;
}

@media screen and (max-width: $desktop) {
    .navbar-link {
        cursor: default;
        &:after {
            display: none !important;
        }
        &:hover {
            background-color: white;
            color: $grey-dark;
        }
    }
}

.app-title {
    font-weight: 500;
    font-size: large;
    align-items: center;
    padding-left: 1rem;
    cursor: default;

    &.mobile {
        display: none;
        @media screen and (max-width: $desktop) {
            display: flex;
        }
    }
    &.desktop {
        display: flex;
        @media screen and (max-width: $desktop) {
            display: none;
        }
    }
}

.production-warning {
    @extend .app-title;
    font-size: medium;
}

// fix dropdown doesnt show up on ie11
@include desktop {
    .navbar-item {
        &.is-hoverable:hover {
            .navbar-dropdown {
                display: block !important;
            }
        }
        &.is-hoverable:focus-within {
            .navbar-dropdown {
                display: none;
            }
        }
    }
}