.navbarPopupWrapper {
    height: 0px;
    width: 0px;
    position: relative;
    top: $navbar-height;
    left: -20px;
}

.navbarPopup {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 10px;
}

.navbarPopupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbarPopupHeaderIcon, .navbarPopupHeaderText, .navbarPopupHeaderCloseIcon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .navbarPopupHeaderCloseIcon {
        cursor: pointer;
    }

    .navbarPopupHeaderText {
        font-weight: bold;
    }

    padding-bottom: 5px;
    border-bottom: solid 1px $link;
}

.navbarPopupBody {
    .navbarPopupText {
        font-size: 0.75em;
    }
}

.infoButton {
    margin-right: 0px !important;
    border: 0px !important;
    color: $info !important;
    :hover, :focus, :active {
        color: $link !important;
    }
}

.dangerButton {
    @extend .infoButton;
    color: $danger !important;
    :hover, :focus, :active {
        color: $danger !important;
    }
}