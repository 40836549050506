// sizing
.no-vertical-padding {
    padding-top: 0px;
    padding-bottom: 0px;
}

.padding-left-sm {
    padding-left: 10px;
}

.padding-right-sm {
    padding-right: 10px;
}

.margin-left-sm {
    margin-left: 10px;
}

.margin-right-sm {
    margin-right: 10px;
}

.full-width {
    width: 100%;
}

// colors
.is-white {
    color: white !important;
}

// functionality
.clickable {
    cursor: pointer;
}

.no-margin {
    margin: 0px !important;
}

.border-none {
    border: none !important;
}

.unset-justify-content {
    justify-content: unset !important;
}

.space-between {
    display: flex;
    justify-content: space-between !important;
}

.display-flex {
    display: flex;
}

.center-vertically {
    align-items: center;
}

.inline-block {
    display: inline-block;
}

.with-text-wrap {
    white-space: normal;
}

.center-content {
    display: flex;
    justify-content: center;
}

.no-underline {
    text-decoration: none !important;
}

.link {
    @extend a;
}