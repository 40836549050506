.wellMapContainer {
    display: flex;
    align-items: center;
    height: calc(100vh - #{$navbar-height} - #{$panel-header-height} - #{2 * $explorer-table-padding-top} - 15rem);
    padding: .5rem;
    position: relative;
}
.wellWrapper {
    position: relative;
    overflow: hidden;
}

.wellMap {
    height: calc(100vh - #{$navbar-height} - #{$panel-header-height} - #{2 * $explorer-table-padding-top});
    width: 50%;
}

.mapHeaderTextWrapper {
    display: flex;
    align-items: center;
    
}

.tooltipWrapper {
    pointer-events: none;
    position: absolute;
    top: 10px;
    // left: 10px;
    z-index: 10;
    display: none;
    max-width: 450px;
    background-color: transparent;
    padding: 0px 15px;
    .tooltipLoading {
        width: 150px;
        height: 100px;
        display: flex;
        align-content: center;
        justify-content: center;
        .tooltipLoadingIcon {
            display: flex;
            align-self: center;
        }
    }
    .tooltipDataWrapper {
        .columns {
            margin: 0.25rem;
            .column {
                padding: 2px 5px;
                .label {
                    margin-bottom: 0.1em;
                }
                .is-size-7 {
                    font-size: 0.62rem;
                }
                .is-size-6 {
                    font-size: .7rem;
                }
                &.is-half {
                    .column.is-full {
                        margin: unset;
                        .field {
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }
    }

    .tooltipDataWrapper, .tooltipLoading {
        pointer-events: auto;
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);
    }
}

// zoom / extent / attribution styling
.zoomToExtentWrapper, .zoomWrapper {
    background-color: white;
    color: success;
}

.zoomWrapper {
    display: flex;
    padding: 0px;
    margin: 0px;
    button {
        margin: 0px;
    }
    .zoomWrapper-in {
        height: $zoom-icon-height;
        width: $zoom-icon-width;
    }
    .zoomWrapper-out {
        height: $zoom-icon-height;
        width: $zoom-icon-width;
        left: calc(.5em  + #{$zoom-icon-width});
        top: .5em;
    }
}

.zoomToExtentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    top: .5em;
    left: calc(.5em + (2 * #{$zoom-icon-width}));
    height: $zoom-icon-height;
    width: $zoom-icon-width;
    button, .zoomToExtentDiv {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &:focus {
        outline: none;
    }
}

.attributionWrapper {
    font-size: x-small;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

// openlayers controls overrides
.ol-control {
    background-color: white;
    color: $color-6;
    &:hover {
        background-color: white;
    }
    
    button { 
        color: $color-6;
        background-color: white;
        border-color: $color-6;
        &:hover, &:focus {
            outline: none;
            background-color: white;
            border-color: $color-6;
        }
    }    
}

// openlayer bounding box style
.ol-dragbox {
    border: solid 2px rgb(235, 162, 80);
    background-color: rgba(235,162,80, 0.3);
}

// icon borders
.zoomToExtentWrapper {
    border: solid 1px $color-6;
    border-left: none;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);
    border-radius: 2px;

}
.zoomWrapper-out {
    border: solid 1px $color-6 !important;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);

}

.zoomWrapper-in {
    border: solid 1px $color-6 !important;
    border-right: none !important;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);

}

.mapLayerControlsPositioning, .searchByAddressPositioning {
    position: relative;
    overflow-y: visible;
    height: 0px;
    z-index: 31;
    width: 30%;
    left: 70%;
}

.searchByAddressPositioning {
    width: 45%;
    left: 25%;
}

.mapLayerControlsPadding, .searchByAddressPadding {
    padding: 20px;   
}

.mapLayerControlsWrapper, .searchByAddressWrapper {
    background-color: white;
    position: relative;
    right: 0px;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.24), 0px 2px 2px 0px rgba(0,0,0,0.24);
}

.mapLayerControlsHeader, .searchByAddressHeader {
    display: flex;
    width: 100%;
}

.mapLayerControlsDivider, .searchByAddressDivider {
    height: 0px;
    margin: 0px;
    border-top: 0px;
    margin: 0px 10px;
}

.searchByAddressText {
    justify-content: center;
    padding-bottom: 7px;
}

.mapLayerIcon {
    margin-right: 10px;
}

.mapLayerControlsTitle, .searchByAddressTitle {
    padding: 2px 10px;
    display: flex;
    align-items: center;
    width: 100%;
}

.mapLayerControlsText, .searchByAddressText {
    display: flex;
    align-items: center;
}

.mapLayerControlsContent, .searchByAddressContent {
    display: flex;
    flex-direction: column;
    padding: 2px 10px;
}

.layerGroupWrapper {
    display: flex;
    // align-items: center;
    flex-direction: column;
    // margin: 3px 0px;
    // .control {
    //     .checkbox {
    //         margin-right: 10px;
    //     }
    // }
    .layerGroupName {
        font-weight: bold;
    }
}

.layerControlWrapper {
    display: flex;
    align-items: center;
    margin: 3px 0px;
    .control {
        .checkbox {
            margin-right: 10px;
        }
    }
}

.layerControlText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
    &.clickable {
        cursor: pointer;
    }
}

.layerControlIcons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

.layerControlIcon {
    min-width: 16px;
}

.mapLayerLegendWrapper {
    padding: 2px 23px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.mapLayerLegendItemWrapper {
    margin-bottom: 3px;
    display: flex;
    align-items: center;
}

.mapLayerLegendItem {
    margin: 0px 10px 0px 3px;
    min-height: 18px;
    height: 18px;
    min-width: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    border: solid 2px black
}

.mapLayerLegendImage {
    @extend .mapLayerLegendItem;
    overflow: hidden;
}

.noBorderLayerLegendImage {
    border: none !important;
}

.mapLayerLegendIcon {
    min-width: unset;
    width: unset;
    height: unset;
    min-height: unset;
}

.mapLayerChevron {
    display: flex;
    align-items: center;
}


.searchBar__menu, .searchBar__menu-list, .searchBar__option {
    z-index: 5 !important;
    cursor: pointer;
}

.searchBar__control {
    max-height: 10rem;
    overflow-y: auto;
}
.searchBar__control--is-focused {
    border-color: hsl(0,0%,80%) !important;
    box-shadow: inherit !important;
}

.searchBar__indicator-separator, .searchBar__dropdown-indicator {
    display: none !important;
}

.searchBar__indicator, .searchBar__option {
    cursor: pointer !important;
}

.searchBar__value-container {
    cursor: text;
}

.searchBar__option {
    color: pink;
    min-height: 30px;
}

.select.searchBar::after {
    display: none !important;
}



.unitsDropDown__menu, .unitsDropDown__menu-list, .unitsDropDown__option {
    z-index: 5 !important;
    cursor: pointer;
}

.unitsDropDown__control {
    max-height: 10rem;
    overflow-y: auto;
}
.unitsDropDown__control--is-focused {
    border-color: hsl(0,0%,80%) !important;
    box-shadow: inherit !important;
}

.unitsDropDown__indicator-separator, .unitsDropDown__dropdown-indicator {
    display: none !important;
}

.unitsDropDown__indicator, .unitsDropDown__option {
    cursor: pointer !important;
}

.unitsDropDown__value-container {
    cursor: text;
}

.unitsDropDown__option {
    color: pink;
    min-height: 30px;
}

.select.unitsDropDown::after {
    display: none !important;
}