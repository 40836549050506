// general layout

.explorerContainer {
    margin: 0rem;
    max-height: calc(100vh - #{$navbar-height});
    overflow-y: scroll;
}

.spaWrapper {
    position: relative;
}

.spaContainer {
    margin: 0rem;
}

.explorerWrapper {
    // padding-top: $explorer-panel-padding-top;
    font-size: .75em;
}

// table

.filterSummaryWrapper {
    display: flex;
    justify-content: center;
    padding: 0rem 0.75rem;
}

.filterSummaryText {
    display: flex;
}

.explorerTableWrapper {
    max-height: $explorer-table-height;
    padding: unset;
    padding-top: 10px;
    margin-bottom: 0px !important;
    overflow-y: scroll;
    .is-visible {
        height: 100%;
        transition: height 2s ease-out;
    }
    .is-hidden {
        transition: height 2s ease-out;
        height: 0;
    }
}

.linkCell {
    cursor: pointer;
    text-decoration: underline;
    color: $link;
}

.headerCell {
    display: flex;
    .headerCellText {
        display: flex;
    }
    .headerCellIcon {
        color: $primary;
        position: relative;
        top: 1px;
        left: 8px;
        .icon {
            display: inline-block;
        }
    }
}
.explorerTable {
    white-space: nowrap;
    cursor: default;

    // disable text selection
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    // margin: 0rem 1rem;
}

.explorerTableHeader {
    display: inline-flex;
}

.is-collapsed {
    display: none !important;
}
.simpleTableHeader {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.nameAndSort {
    display: flex;
    flex-direction: row;
}

.explorerTableArrow {
    position: relative;
    top: 1px;
    left: 2px;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
    background-color: #f5f5f5;
}

.table {
    td {
        border-bottom: none;
    }
    .highlightedRow {
        background-color: $primary-highlight !important;
        td {
            .linkCell {
                color: white !important;
            }
        }
    }
}

.tableFooterWrapper {
    display: flex;
    min-height: $explorer-tablefooter-height;
    width: 100%;
}

.tableFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-top: solid 2px #f5f5f5;
    padding: 0.5rem 0rem;
    div {
        display: flex;
        flex-wrap: nowrap;
    }
    .button {
        margin: 0px;
        display: flex;
    }
    .buttons {
        margin-bottom: unset;
    }
    .pageNavigator, .resultsCount, .buttons {
        display: flex;
        // flex-basis: 100%;
        justify-content: center;
        margin-top: 0.25rem;
    }
    span {
        align-self: center;
    }
    .field {
        margin-bottom: 0rem;
    }
}
.tableFooterButtons {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

// filter

.explorerFilter {
    max-height: calc(
        100vh - #{$navbar-height} - #{$explorer-filterbar-height} - #{$explorer-tabs-bottom-padding} -  #{2 * $explorer-panel-padding-top} - #{$panel-header-height} - 2.25rem
    );
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    .is-visible {
        height: 100%;
        transition: height 2s ease-out;
    }
    .is-hidden {
        transition: height 2s ease-out;
        height: 0;
    }
}

.explorerFilterIconWrapper {
    height: 98%;
    margin-top: 0px !important;
}

.explorerFilterIcon {
    width: 5rem;
    height: 78%;
    border: solid 1px;
    border-radius: 3px;
    margin-right: 3rem;
}


.exportButtonWrapper {
    display: flex;
    align-items: center;
    padding: 1px 2rem;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    .exportButtonIconWrapper {
        display: flex;
        padding: 0rem .5rem;
    }
    @media screen and (max-width: $tablet) {
        padding: 0rem;
        .exportButtonText {
            display: none;
        }
    }
    a:hover {
        background-color: #00c4a7 !important;
    }
}

.explorerForm {
    margin-bottom: unset !important;
    margin-top: unset !important;
    padding: 0px 10px;
}


// tabs 

.explorerTableHeaderWrapper {
    // display: flex;
    height: $explorer-filterbar-height;
}

.explorerTableHeader {
    // flex-grow: 1;
    height: 100%;
}

.tabsWrapper {
    padding-bottom: $explorer-tabs-bottom-padding
}

.filterResultsCount {
    font-weight: bold;
}

.filterResultsLoadingWrapper {
    display: flex;
    align-items: center;
}