
// input
.formInputWrapper {
    margin-top: 10px;
}

.betweenAndText {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    margin-bottom: 0.25rem;
}

.betweenInputsWrapper {
    width: 100%;
}

.checkboxLabel {
    display: flex;
    align-items: center;
}

.formCheckbox {
    margin-right: 10px;
}
.formHelper {
 &:hover {
     z-index: 40;
 }
 &.inline {
     margin-left: 10px;
 }
 &.vertically-centered {
     display: flex;
     align-items: center;
 }
}

.formSelect {
    // max-width: 30%;
    option {
        text-overflow: ellipsis;
    }
    // display: flex;
    // margin-right: 20px;
}

.is-changed {
    border: solid 2px;
    // border-color: $cyan;
    border-color: #ffae42;
}

@media print{ 
    * {
        word-wrap: break-word !important;
        white-space: pre-wrap !important;
    }
    table {
        table-layout: fixed !important;
        width: 100% !important;
    }
    html.has-navbar-fixed-top {
        padding-top: 0px !important;
    }
    .navbar {
        display: none;
    }
    .pageContent { 
        height:100% !important;
        overflow:visible !important;
    }
    .column {
        &.is-one-third {
            width: 100% !important;
        }
        display: block !important;
        padding: 2px !important;
        margin-top: 0px !important;
    } 
    .columns {
        display: block !important;
        &.is-hidden { display: none !important;}
        padding: 2px !important;
    }
    .subtitle {
        margin-bottom: 0.2rem !important;
        border-bottom: solid 1px grey !important;
    }
    .formWrapper {  
        padding: 0px !important;
        margin: 0px !important;
    }

    .formSectionWrapper, .formTable {
        display: block !important;
        break-after: page !important;
    }

    body {
        white-space: pre-wrap !important;
        font-size: 1.2vw !important;
    }
    .control {
        font-size: 1.2vw !important;
    }
    .field-label, .field-body  {
        font-size: 1.2vw !important;
        word-break: break-word !important;
        padding-bottom: 0px !important;
        display: flex !important;
        align-items: center !important;
        padding-top: 0px !important;
    }

    .reactSelect__single-value, .reactSelect__value-container {
        // padding-bottom: 0px;
        // padding-top: 0px;
    }
    .input, .textarea, .select, .reactSelect__single-value, .reactSelect__value-container {
        word-break: break-word !important;
        white-space: pre-wrap !important;
        overflow-x: visible;
        position: relative !important;
        font-size: 1.2vw !important;
        transform: unset !important;
        padding-left: 0px !important;
    }

    .is-size-7 {
        font-size: 1.2vw !important;
    }

    input {
        white-space: pre-wrap !important;
    }
} 

.is-printable {
    border: none !important;
    box-shadow: none !important;
    background-color: white !important;
    cursor: default !important;
    resize: none !important;
    overflow: visible;
    color: #363636 !important;
    padding-top: 2px !important;
    .reactSelect__control {
        border: none !important;
        box-shadow: none !important;
        background-color: white !important;
        cursor: default !important;
        resize: none !important;
        overflow: visible;
        .reactSelect__value-container {
            padding-left: 0px !important;
        }
        .reactSelect__single-value {
            margin-top: -2px;
            color: #363636 !important;

        }
    }
    .reactSelect__indicators {
        display: none;
    }
}

.changedSelect {
    border: solid 2px;
    border-radius: 4px !important;
    // border-color: $cyan;
    border-color: #ffae42;
}

.formComparisonOperatorWrapper {
    // margin-left: 40px;
    display: flex;
    align-items: center;
}

.formComparisonWidthLimiter {
    max-width: 30%;
}

.formSubmitWrapper {
    display: flex;
    width: 100%;
    margin-top: 10px;
    .formSubmitButton {
        display: flex;
    }
}

.formInput {
    display: flex;
}

.inputLabel {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

form.columns {
    margin-left: unset;
    margin-right: unset;
}

// classes for hiding input arrows
input.input-no-arrows::-webkit-outer-spin-button,
input.input-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].input-no-arrows {
  -moz-appearance: textfield;
}

// .formInputLayout {
//     display: flex;
//     justify-content: space-evenly;
// }

// select

.reactSelect__menu, .reactSelect__menu-list, .reactSelect__option {
    z-index: 5 !important;
}

.reactSelect__control {
    max-height: 10rem;
    overflow-y: auto;
}

.reactSelect__option {
    color: pink;
    min-height: 30px;
    font-size: 0.75rem
}

// .reactSelect__indicator {
//     color: $primary !important;
// }

.reactSelect__multi-value__label {
    white-space: unset !important;
}

// .select::after {
//     border-color: $primary !important;
// }

.fieldGroupSeparator {
    margin: 0rem;
    background-color: $primary;
}

.fieldGroupSeparatorText {
    white-space: unset;
}

.formInputLayout {
    padding: 0rem .5rem;
    label {
        display: flex;
    }
    .field-label, .field-body {
        margin-right: 0.4rem;
        padding-bottom: 8px;
    }
}

// results summary
.formResultsSummaryWrapper {
    padding-top: 10px;
    span {
        font-size: .75rem !important;
    }
}

// date picker
.react-datepicker-wrapper {
    display: flex;
}
.react-datepicker-popper {
    z-index: 5;
}

.react-datepicker {
    font-family: unset;
    font-size: unset;
    display: flex;
    flex-direction: column;
}

.react-datepicker__current-month {
    display: none;
}

.react-datepicker-time__input {
    font-size: 0.8rem;
    width: inherit;
}

.react-datepicker-time__input-container {
    display: inline-block;
    width: 80%;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: 100%;
}

.react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll {
    padding: 2px 10px;
    border-radius: 4px;
    display: flex;
    flex-basis: 30%;
    @extend .button;
    @extend .is-small;
    font-weight: bold;
    font-size: 0.9em;
}

.react-datepicker__month-read-view, .react-datepicker__year-read-view {
    visibility: visible !important;
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
    display: none;
}

.react-datepicker__month-option, .react-datepicker__month-year-option {
    padding: 2px 10px;
}

.react-datepicker__year-option {
    padding: 5px 15px;
    font-size: 0.8em;
}

.react-datepicker__header__dropdown, .react-datepicker__header__dropdown--scroll {
    display: flex;
    justify-content: center;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
    width: unset;
    left: unset;
    background-color: $white;
    font-weight: 400;
}

.react-datepicker__navigation {
    top: 20px;
}

.react-datepicker__navigation--previous {
    border-right-color: $dark;
    &:hover {
        border-right-color: $dark;
    }
}
.react-datepicker__navigation--next {
    border-left-color: $dark;
    &:hover {
        border-left-color: $dark;
    }
}

.react-datepicker__header {
    background-color: $white;
}
.react-datepicker__day-name {
    font-weight: bold;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
    display: none;
}

.hidden-input {
    display: none;
}

// 