
// theme
$color-1: $primary;
$color-2: $info;
$color-3: $link;
// $color-4: rgba(246,166,35,1);
$color-4: $danger;
//$color-5: $warning;
$color-5: rgb(221, 107, 72);
$color-6: $success;

$colors: (
    1: $color-1,
    2: $color-2,
    3: $color-3,
    4: $color-4,
    5: $color-5,
    6: $color-6,
);

$helpers: (
    0: 'primary',
    1: 'info',
    2: 'link',
    3: 'danger',
    4: 'warning',
    5: 'success',
);

@each $i, $color in $colors {
    .background-#{$i} {
        background-color: $color !important;
    }
    .color-#{$i} {
        color: $color !important;
    }
    .carat-class-#{$i} {
        .reactSelect__indicator {
            color: $color !important;
        }
        &:after {
            border-color: $color !important;
        }
        .select {
            &:after {
                margin-top: -0.3375em !important;
                border-color: $color !important;
            }
        }
    }
    .hover-color-#{$i} {
        .input:focus ~ .icon:hover {
            color: $color !important;
            cursor: pointer;
        }
        .icon {
            pointer-events: initial !important;
            &:hover {
                cursor: pointer;
                color: $color !important;
            }
            &:focus {
                color: $color !important;
            }
        }
    }
    .tooltip-#{$i} {
        background-color: darken($color, 20%) !important;
        &::after {
            border-top-color: darken($color, 20%) !important;
        }
        a {
            color: darken($color, 50%);
            text-decoration: underline;
        }
    }
    .icon-color-#{$i} {
        .icon {
            color: $color;
        }
    }
    .tabs-#{$i} {
        li.is-active {
            div {
                border-bottom-color: #3273dc;
                color: $color;
            }
        }
    }
    .border-#{$i} {
        border: solid 1px $color;
    }
};


@each $i, $helper in $helpers {
    
    .is-#{$i} {
        @extend .is-#{$helper}
    }
};