// Bulma overrides
$table-cell-border: 0px;
$navbar-height: 2.3rem;

// panel
$panel-header-height: 1rem;

// explorer table
$explorer-panel-padding-top: 0.5rem;
$explorer-filterbar-height: 5rem;
$explorer-tablefooter-height: 3rem;
$explorer-table-padding-top: 1rem;
$explorer-tabs-bottom-padding: 1rem;
$explorer-table-height: calc(100vh - #{$navbar-height} - #{$explorer-filterbar-height} - #{$explorer-tablefooter-height} - #{$explorer-tabs-bottom-padding} - #{2 * $explorer-panel-padding-top} - #{$panel-header-height});

// detail panel
$well-detail-panel-height: 500px;

// map
$zoom-icon-height: 2rem;
$zoom-icon-width: 2rem;

$primary: hsl(171, 100%, 41%);
$primary-highlight: rgb(50, 115, 220);
$secondary: $info;