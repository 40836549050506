$detail-chart-height: 250px;

// chart
.chart {

}

.chartTitle {
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.chartWrapper {
    height: 300px;
    width: 100%;
    min-height: $detail-chart-height;
    min-width: 100%;
    font-size: 0.8rem;
    user-select: none;
}

.aggregateChartWrapper {
    height: 500px;
    width: 100%;
    min-height: $detail-chart-height;
    min-width: 100%;
    font-size: 0.8rem;
    user-select: none;
}
.recharts-tooltip-wrapper {
    z-index: 10;
    // transition: none !important;
}
.recharts-default-legend {
    padding: 0.5em 1em;
}
.nitrateChartYAxisLabel {
    transform-origin: 21px 47px;
    font-weight: bold;
}

.detailChartYAxisLabel {
    transform-origin: 28px 38px;
    font-weight: bold;
}

.nitrateChartYAxisRightLabel {
    transform-origin: -47px 10px;
    font-weight: bold;
}

.nitrateChartAxisTick {
}

.nitrateChartXAxisLabel {
    font-weight: bold;
}

.chartMessageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
    height: $detail-chart-height;
    min-height: $detail-chart-height;
}

.chartMessageText {
    font-size: 1.5rem;
}

.chartMessageSubtext {
    font-size: 1rem;
    text-align: center;
}

// tooltip
.customTooltip {
    margin: 0px;
    padding: 10px; 
    background-color: rgb(255, 255, 255); 
    border: 1px solid rgb(204, 204, 204); 
    white-space: nowrap;
}

.customTooltipLabel {
    margin: 0px;
    font-weight: 500;
}

.customTooltipList {
    padding: 0px; margin: 0px;
    li {
        display: block; 
        padding-top: 4px; 
        padding-bottom: 4px; 
        // color: rgb(136, 132, 216);
        .customTooltipItemName {
            font-weight: 500;
        }
    }
}

.legendWrapper {
    display: flex;
    justify-content: center;
}

.legendItem {
    margin: 0px 10px;
}

.chartFooter {
    margin-top: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        text-align: center;
    }
}

// hydrograph chart
.hydrographChartPanelWrapper {
    position: relative;
    z-index: 20;
    height: 0px;
    padding: 0px 1.5rem 0px 0px;
    left: 5%;
    top: 15px;
}

.hydrographChartPanelHeader {
    cursor: pointer;
    background-color: $primary-dark !important;
}